<template>
  <div class="main-wrapper">
    <!-- :style="`padding-top: ${user.verified || (!Object.keys(user).length && user !== 'ban') ? '97px' : '130px'}`" -->
    <div :class="{ 'fixed-header': !$route.meta.noStickyHead }" class="">
      <Header v-if="user !== 'ban'" />
      <PopUpDefault
        class="tgPopup"
        :noScrollLock="true"
        @close="tgPopupState = false"
        v-if="tgPopupState"
      >
        <div class="d-flex">
          <svg
            style="flex-shrink: 0"
            class="mr-3"
            width="38"
            height="38"
            viewBox="0 0 38 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_12136_9929)">
              <path
                d="M19 0C13.962 0 9.12594 2.00302 5.56641 5.56492C2.0032 9.12824 0.00098702 13.9608 0 19C0 24.0371 2.00391 28.8732 5.56641 32.4351C9.12594 35.997 13.962 38 19 38C24.038 38 28.8741 35.997 32.4336 32.4351C35.9961 28.8732 38 24.0371 38 19C38 13.9629 35.9961 9.12683 32.4336 5.56492C28.8741 2.00302 24.038 0 19 0Z"
                fill="url(#paint0_linear_12136_9929)"
              />
              <path
                d="M8.60046 18.7994C14.1401 16.3864 17.8333 14.7954 19.6798 14.0268C24.9583 11.832 26.0537 11.4508 26.7692 11.4379C26.9266 11.4354 27.2769 11.4743 27.5055 11.6591C27.6955 11.8149 27.7489 12.0257 27.7756 12.1737C27.7994 12.3216 27.832 12.6585 27.8053 12.9216C27.5203 15.9259 26.2823 23.2166 25.653 26.5817C25.3887 28.0055 24.8633 28.4828 24.3556 28.5295C23.2512 28.631 22.4141 27.8003 21.3453 27.1C19.6739 26.0036 18.7298 25.3214 17.1059 24.2518C15.2297 23.0156 16.4469 22.336 17.5156 21.2257C17.7947 20.9351 22.6575 16.5131 22.7495 16.1121C22.7614 16.0619 22.7733 15.8749 22.6605 15.7763C22.5506 15.6774 22.3873 15.7113 22.2686 15.738C22.0994 15.776 19.4305 17.5418 14.253 21.0351C13.4959 21.5559 12.8101 21.8097 12.1926 21.7963C11.5158 21.7818 10.2095 21.4128 9.23874 21.0975C8.05124 20.7107 7.10421 20.5061 7.18733 19.8491C7.2289 19.5071 7.70093 19.1571 8.60046 18.7994Z"
                fill="white"
              />
            </g>
            <defs>
              <linearGradient
                id="paint0_linear_12136_9929"
                x1="1900"
                y1="0"
                x2="1900"
                y2="3800"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#2AABEE" />
                <stop offset="1" stop-color="#229ED9" />
              </linearGradient>
              <clipPath id="clip0_12136_9929">
                <rect width="38" height="38" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <div class="modal-popup__text">
            Больше информации в удобном формате в нашем новом Телеграм-канале
            для педиатров
          </div>
        </div>

        <div
          class="
            modal-popup__btn-accept
            d-md-inline-flex
            button button_pink
            ml-auto
          "
          @click="goToTg()"
        >
          Присоединиться
        </div>
      </PopUpDefault>
      <PopUpDefault
        class="tgPopup tgPopup_top tpPopup"
        :noScrollLock="true"
        @close="tpPopupState = false"
        v-if="tpPopupState"
      >
        <div class="d-flex">
          <svg
            class="mr-4"
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="20" cy="20" r="20" fill="#C4D600" />
            <path
              d="M29.1372 18.7865V18.4256C29.1372 15.9257 28.1744 13.5283 26.4607 11.7607C24.747 9.99305 22.4226 9 19.9991 9C17.5755 9 15.2512 9.99305 13.5374 11.7607C11.8237 13.5283 10.8609 15.9257 10.8609 18.4256V18.7865C9.87792 19.1934 9.06296 19.9407 8.55602 20.9C8.04909 21.8593 7.88182 22.9707 8.08295 24.0433C8.28407 25.116 8.84104 26.0829 9.65818 26.778C10.4753 27.4732 11.5016 27.8531 12.5609 27.8527C13.18 27.8518 13.7736 27.5978 14.2114 27.1462C14.6492 26.6946 14.8955 26.0824 14.8963 25.4438V20.854C14.8944 20.2706 14.6871 19.7078 14.3128 19.2704C13.9385 18.833 13.4228 18.5509 12.8618 18.4766V18.4276C12.8618 16.4749 13.6139 14.6022 14.9526 13.2214C16.2913 11.8406 18.1069 11.0649 20.0001 11.0649C21.8932 11.0649 23.7089 11.8406 25.0475 13.2214C26.3862 14.6022 27.1383 16.4749 27.1383 18.4276V18.4766C26.5773 18.5509 26.0616 18.833 25.6873 19.2704C25.313 19.7078 25.1057 20.2706 25.1039 20.854V25.4438C25.1052 25.9856 25.2836 26.5111 25.6102 26.9354C25.9368 27.3598 26.3925 27.6582 26.9038 27.7825C26.7334 28.1289 26.4735 28.42 26.1529 28.6237C25.8323 28.8274 25.4634 28.9359 25.0869 28.9372H22.037C21.7718 28.9372 21.5174 29.0458 21.3299 29.2393C21.1424 29.4327 21.037 29.695 21.037 29.9686C21.037 30.2421 21.1424 30.5045 21.3299 30.6979C21.5174 30.8913 21.7718 31 22.037 31H25.0869C26.0406 30.9984 26.9632 30.6501 27.6926 30.0162C28.4219 29.3824 28.9113 28.5036 29.0747 27.5345C29.9299 27.1966 30.6666 26.6006 31.1884 25.8243C31.7102 25.048 31.993 24.1275 31.9999 23.1828C32.0067 22.2382 31.7373 21.3134 31.2268 20.5292C30.7163 19.7449 29.9884 19.1376 29.1382 18.7865H29.1372Z"
              fill="white"
            />
          </svg>

          <div v-if="!interestSended">
            <div class="modal-popup__text">
              Не пропустите самое важное в&nbsp;{{ tpNounSpeciality }}
            </div>
            <div class="modal-popup__description">
              Оставьте заявку, и мы будем держать Вас в курсе всех новых
              терапевтических возможностей для&nbsp;Ваших пациентов
            </div>
          </div>
          <div v-else>
            <transition name="interest-v">
              <div class="modal-popup__text">Спасибо за Ваше обращение!</div>
            </transition>
            <transition name="interest-v">
              <div class="modal-popup__description">
                Наш оператор свяжется с Вами в ближайшее время
              </div>
            </transition>
          </div>
        </div>

        <div
          v-if="!interestSended"
          class="
            modal-popup__btn-accept
            d-md-inline-flex
            button button_pink
            ml-auto
          "
          @click="sendInterest()"
        >
          Мне интересно!
        </div>
      </PopUpDefault>
    </div>

    <router-view v-if="user !== 'ban'" />
    <div v-else>
      <Error
        class="allPage"
        :title="'Доступ заблокирован'"
        :description="'Вы несколько раз ввели неверный пароль. Доступ к порталу временно заблокирован. Пожалуйста, повторите попытку через 15 минут'"
      />
    </div>
    <div class="bottom-tip" v-if="user !== 'ban'">
      <div class="content" v-if="pageApproval && pageApproval.length">
        {{
          `Номер одобрения: ${pageApproval[0].number} Дата одобрения: ${
            pageApproval[0].approval_date
              ? this.$m(pageApproval[0].approval_date).format("DD.MM.YYYY")
              : "            "
          } Дата истечения: ${
            pageApproval[0].expire_date
              ? this.$m(pageApproval[0].expire_date).format("DD.MM.YYYY")
              : "        "
          }`
        }}
      </div>
    </div>
    <Footer v-if="user !== 'ban'" />
    <!-- <HelperStart
      @closeHelp="closeHelp"
      @startHelp="startHelp"
      v-if="helperStart"
      :text="helperText"
    /> -->
    <Helper
      v-if="showHelper && helperTargets[currentTarget]"
      @setPosition="setPosition"
      @nextStep="nextStep"
      :target="helperTargets[currentTarget]"
      :position="helperPosition"
      :index="currentTarget"
      :length="helperTargets.length"
      @close="closeOnboarding"
    />
    <PopUpDefault
      @close="closeProfiling"
      v-if="profiling.opened && profiling.survey.template_type == 2"
      class="profiling-modal__survey"
    >
      <ProfilingModal @close="closeProfiling" :survey="profiling.survey" />
    </PopUpDefault>
    <ProfilingMini
      @toggle="surveyMiniToggle"
      @close="closeProfiling"
      :hidden="profiling.hidden"
      v-if="profiling.opened && profiling.survey.template_type == 1"
      :survey="profiling.survey"
    />
  </div>
</template>

<script>
import Header from "../components/main/Header.vue";
import Footer from "../components/Footer.vue";
import Error from "@/views/Error.vue";
import { mapGetters, mapActions } from "vuex";
import PopUpDefault from "@/components/pageComponents/PopUpDefault.vue";
import ProfilingModal from "@/components/pageComponents/profiling/ProfilingModal.vue";
import ProfilingMini from "@/components/pageComponents/profiling/ProfilingMini.vue";
import Helper from "@/components/main/Helper.vue";
import HelperStart from "@/components/main/HelperStart.vue";
import { bus } from "@/main";
export default {
  components: {
    Header,
    Footer,
    Error,
    PopUpDefault,
    Helper,
    HelperStart,
    ProfilingModal,
    ProfilingMini,
  },
  name: "main-layout",
  data: () => ({
    profiling: {
      opened: false,
      hidden: false,
      survey: null,
    },
    tgSpeciality: ["пульмонология", "неонатология", "педиатрия"],
    tpSpeciality: [
      "кардиология",
      "нефрология",
      "эндокринология",
      "неонатология",
      "ревматология",
      "терапия",
      "общая врачебная практика (семейная медицина)",
      "онкология",
      "педиатрия",
      "гастроэнтерология",
      "пульмонология",
      "аллергология",
      "аллергология Детская",
      "иммунология",
      "химиотерапия",
    ],
    tpNoun: [
      { value: "Кардиология", noun: "кардиологии" },
      { value: "Нефрология", noun: "нефрологии" },
      { value: "Эндокринология", noun: "эндокринологии" },
      { value: "Неонатология", noun: "неонатологии" },
      { value: "Ревматология", noun: "ревматологии" },
      { value: "Терапия", noun: "терапии" },
      {
        value: "Общая врачебная практика (семейная медицина)",
        noun: "терапии",
      },
      { value: "Онкология", noun: "онкологии" },
      { value: "Педиатрия", noun: "педиатрии" },
      { value: "Гастроэнтерология", noun: "гастроэнтерологии" },
      { value: "Пульмонология", noun: "пульмонологии" },
      { value: "Аллергология", noun: "аллергологии" },
      { value: "Аллергология Детская", noun: "аллергологии" },
      { value: "Иммунология", noun: "иммунологии" },
      { value: "Химиотерапия", noun: "химиотерапии" },
    ],
    tgPopupState: false,
    tpPopupState: false,
    interestSended: false,
    lastKnownScrollY: 0,
    currentScrollY: 0,
    ticking: false,
    eleHeader: null,
    headerContainer: null,
    classes: {
      pinned: "header-pin",
      unpinned: "header-unpin",
    },
    showHelper: false,
    helperStart: false,
    helperText: {
      title: "Настройка «Моего AZ–MOST»",
      description:
        "Настройте портал по интересующим терапевтическим областям и заболеваниям",
    },
    currentTarget: 0,
    helperPosition: {},
  }),
  computed: {
    ...mapGetters(["user", "pageVerify", "Nosologys"]),
    helperTargets() {
      return [
        {
          title: "Воспользуйтесь фильтром",
          description:
            "Чтобы отсортировать материалы по одному или нескольким критериям",
          icon: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/onboarding/filter/icon1.svg`,
          key: "filterElem",
          position: "bottom",
        },
      ];
    },
    pageApproval() {
      return this.pageVerify && this.pageVerify.length
        ? this.pageVerify
        : this.$route.meta.pageVerify || [];
    },
    tpNounSpeciality() {
      return this.tpNoun.find(
        (el) => el.value.toLowerCase() === this.user.speciality.toLowerCase()
      ).noun;
    },
  },
  methods: {
    ...mapActions(["fetchPageVerify", "fetchNosologys", "checkTutorial"]),
    closeProfiling() {
      this.$set(this.profiling, "opened", false);
      localStorage.setItem("profiling_shown", 1);
    },
    surveyMiniToggle(state) {
      this.$set(this.profiling, "hidden", state);
      if (state) {
        localStorage.setItem("profiling_shown", 1);
      }
    },
    goToTg() {
      if (this.user.invite_link) {
        window.open(this.user.invite_link, "_blank");
        this.tgPopupState = false;
        if (typeof ym !== "undefined") {
          ym(91468266, "reachGoal", "tg-popup", {
            "tg-popup": {
              ...this.$root.ymFields,
            },
          });
        }
      }
    },
    sendInterest() {
      this.$axios({
        method: "POST",
        url: "/api/user/click",
      })
        .then((response) => {
          this.interestSended = true;
        })
        .catch((error) => {});
    },
    onScroll() {
      this.currentScrollY = window.pageYOffset;
      // this.update();
    },
    update() {
      this.headerContainer = document.querySelector(".fixed-header");
      if (!this.headerContainer) return;
      this.eleHeader = this.headerContainer.querySelector(".header");
      if (this.currentScrollY < this.lastKnownScrollY) {
        this.pin();
      } else if (
        this.currentScrollY > this.lastKnownScrollY &&
        this.currentScrollY > this.eleHeader.offsetHeight
      ) {
        this.unpin();
      }
      this.lastKnownScrollY = this.currentScrollY;
    },
    pin() {
      // if (this.eleHeader.classList.contains(this.classes.unpinned)) {
      //   this.eleHeader.classList.remove(this.classes.unpinned);
      //   this.eleHeader.classList.add(this.classes.pinned);
      //   this.headerContainer.style.height = this.eleHeader.offsetHeight + "px";
      // }
    },
    unpin() {
      // if (
      //   this.eleHeader.classList.contains(this.classes.pinned) ||
      //   !this.eleHeader.classList.contains(this.classes.unpinned)
      // ) {
      //   this.eleHeader.classList.remove(this.classes.pinned);
      //   this.eleHeader.classList.add(this.classes.unpinned);
      // }
    },

    startHelp() {
      this.helperStart = false;
    },
    closeHelp(check) {
      if (check) {
        this.checkTutorial({ id: this.user.id, flags: ["filter"] });
      }
      this.helperStart = false;
    },

    startOnboarding() {
      setTimeout(() => {
        this.showHelper = true;
        let routeArray = this.$route.path.split("/");
        ym(91468266, "reachGoal", "onboarding", {
          onboarding: {
            [routeArray[routeArray.length - 1]]: 1,
            ...this.$root.ymFields,
          },
        });
      }, 1000);
    },
    closeOnboarding() {
      this.showHelper = false;
      document.querySelector("body").style.pointerEvents = "";
      document.querySelectorAll(".highlightedElement").forEach((item) => {
        item.classList.remove("highlightedElement");
      });
      window.scrollTo({ top: 0, behavior: "smooth" });
      bus.$emit("scrollLock", false);
      bus.$emit("closeFilter");
      document.querySelector(".fixed-header .header").style.position = "";
      this.checkTutorial({ id: this.user.id, flags: ["filter"] });
    },

    setPosition(target) {
      document.querySelector("body").style.pointerEvents = "none";
      bus.$emit("scrollLock", true);
      let relativePos = {};
      if (!target.key && document.querySelector(`[data-id="${target.key}"]`))
        return;
      let child = document.querySelector(`[data-id="${target.key}"]`);
      let parentPos = document.body.getBoundingClientRect();
      let childPos = child.getBoundingClientRect();
      if (!target.key.includes("header")) {
        const yOffset = -100;
        const y =
          child.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
      document.querySelectorAll(".highlightedElement").forEach((item) => {
        item.classList.remove("highlightedElement");
      });
      child.classList.add("highlightedElement");
      relativePos.top = childPos.top + window.pageYOffset;
      relativePos.right = childPos.right - parentPos.right;
      relativePos.bottom = childPos.bottom - parentPos.bottom;
      relativePos.left = childPos.left;
      relativePos.childWidth = childPos.width;
      relativePos.childHeight = childPos.height;
      this.helperPosition = relativePos;
    },
    nextStep() {
      let routeArray = this.$route.path.split("/");
      if (this.currentTarget < this.helperTargets.length - 1) {
        if (typeof ym !== "undefined") {
          ym(91468266, "reachGoal", "onboarding", {
            onboarding: {
              [routeArray[routeArray.length - 1]]: this.currentTarget + 1,
              ...this.$root.ymFields,
            },
          });
        }
        this.currentTarget++;
        this.setPosition(this.helperTargets[this.currentTarget]);
      } else {
        this.showHelper = false;
        document.querySelector("body").style.pointerEvents = "";
        document.querySelectorAll(".highlightedElement").forEach((item) => {
          item.classList.remove("highlightedElement");
        });
        window.scrollTo({ top: 0, behavior: "smooth" });
        bus.$emit("scrollLock", false);
        bus.$emit("closeFilter");
        this.checkTutorial({ id: this.user.id, flags: ["filter"] });
        if (typeof ym !== "undefined") {
          ym(91468266, "reachGoal", "onboarding finish - filters", {
            onboarding: {
              finish: "filters",
              ...this.$root.ymFields,
            },
          });
        }
      }
    },
  },
  beforeDestroy() {
    bus.$off("startFilterHelp", this.startOnboarding);
  },
  created() {
    bus.$on("startFilterHelp", this.startOnboarding);
  },
  async mounted() {
    await this.fetchPageVerify(this.$route.path);
    if (!Object.keys(this.Nosologys).length) {
      await this.fetchNosologys();
    }
    this.headerContainer = document.querySelector(".fixed-header");
    if (this.headerContainer) {
      this.eleHeader = this.headerContainer.querySelector(".header");
      this.headerContainer.style.height = this.eleHeader.offsetHeight + "px";
    }

    // document.addEventListener("scroll", this.onScroll, false);
    if (
      localStorage.getItem("token") &&
      Object.keys(this.user).length &&
      this.user.speciality &&
      !this.user.tg_id &&
      this.tgSpeciality.includes(this.user.speciality.toLowerCase()) &&
      this.user.invite_link &&
      this.user.flags &&
      this.user.flags.includes("tutorial") &&
      this.user.flags.includes("myazmost") &&
      this.user.flags.includes("filter")
    ) {
      setTimeout(() => {
        if (this.$route.name && this.$route.name !== "Events") {
          this.tgPopupState = true;
        }
      }, 20000);
    }

    if (
      localStorage.getItem("token") &&
      Object.keys(this.user).length &&
      this.user.speciality &&
      this.tpSpeciality.includes(this.user.speciality.toLowerCase()) &&
      this.user.flags &&
      this.user.flags.includes("tutorial") &&
      !this.user.is_click
    ) {
      setTimeout(() => {
        if (
          this.$route.name &&
          this.$route.name !== "Events" &&
          this.$route.name !== "10Years"
        ) {
          // this.tpPopupState = true;
        }
      }, 2000);
    }
    if (
      localStorage.getItem("token") &&
      this.user &&
      Object.keys(this.user).length
    ) {
      this.$axios({
        method: "GET",
        url: "/api/sso/speciality_questions/",
      }).then((res) => {
        const filtered = {
          ...res.data,
          questions: [...res.data.questions].filter((el) => !el.is_completed),
        };
        if (filtered.questions.length) {
          this.$set(this.profiling, "survey", filtered);
          if (
            localStorage.getItem("profiling_shown") &&
            parseInt(localStorage.getItem("profiling_shown")) < 10
          ) {
            // this.$set(this.profiling, "hidden", true);
            if (filtered.template_type == 1) {
              this.$set(this.profiling, "opened", true);
            }
          } else {
            this.$set(this.profiling, "opened", true);
          }
        }
      });
    }
  },
  beforeDestroy() {
    // document.removeEventListener("scroll", this.onScroll, false);
  },
  watch: {
    async $route(to, from) {
      if (to.path !== from.path) {
        await this.fetchPageVerify(this.$route.path);
        this.tgPopupState = false;
      }
      if (to.name && to.name === "Events" && this.$route.name === "10Years") {
        this.tpPopupState = false;
        this.tgPopupState = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.fixed-header {
  position: relative;
  @media screen and (min-width: 1221px) {
    height: 97px;
  }
  & .header {
    @media screen and (min-width: 1221px) {
      position: fixed;
      z-index: 10000;
      top: 0;
      left: 0;
      width: 100vw;
      background-color: #fff;
      // transform: translateY(0);
      transition: 0.3s;

      &-unpin {
        // transform: translateY(-100%);
        // transform: translateY(0);
      }

      &-pin {
        // transform: translateY(0);
      }
    }
  }
}

.main-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  @media screen and (min-width: 1221px) {
    // padding-top: 130px;
  }
  @media screen and (max-width: 1220px) {
    padding-top: 0px !important;
  }
}

.bottom-tip {
  margin-top: 80px;
  margin-bottom: 24px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #b2b4b4;

  @media screen and (max-width: 1220px) {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  @media screen and (max-width: 767px) {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}
</style>

<style lang="scss">
.profiling-modal {
  &__survey {
    & .modal-popup {
      &__content {
        background: #fff;
        border-radius: 20px;
        padding: 0;
        @media screen and (max-width: 1220px) {
          padding: 16px;
        }
      }
    }
  }
}
</style>